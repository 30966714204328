import React from "react";
import "../Pages/contactUs.css";
export default function ContactUs() {
  return (
    <div>
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: "url('/Images/contact1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "20em",
          borderRadius: "16px",
        }}
      ></div>

      <section className="py-5 bg-light">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center">
              <img
                src="/Images/logo.png"
                className="img-fluid mb-4"
                alt="Contact Illustration"
              />
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-4">
                <h5 className="mb-2 mb-sm-0">Follow us on:</h5>
                <ul className="list-inline mb-0 ms-sm-2">
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-linkedin"
                      href="https://www.linkedin.com/company/ajara-global-traders/?viewAsMember=true"
                    >
                      <i className="fab fa-linkedin fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-facebook"
                      href="https://www.facebook.com/profile.php?id=61560915515980"
                    >
                      <i
                        className="fab fa-facebook fa-2x"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-instagram"
                      href="https://www.instagram.com/ajara_global_traders/"
                    >
                      <i
                        className="fab fa-instagram fa-2x"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>

                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-whatsapp"
                      href="https://wa.me/919209469493"
                    >
                      <i className="fab fa-whatsapp fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className=" align-items-center d-flex justify-content-center">
                Let's talk
              </h2>
              <p>
                To request a quote or want to meet up for coffee, contact us
                directly or fill out the form and we will get back to you
                promptly.
              </p>
              <form>
                <div className="mb-4">
                  <label htmlFor="yourName" className="form-label">
                    Your name *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="yourName"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="emailInput" className="form-label">
                    Email address *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="mobileInput" className="form-label">
                    Mobile Number *
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobileInput"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="textareaBox" className="form-label">
                    Message *
                  </label>
                  <textarea
                    className="form-control"
                    id="textareaBox"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <div className="d-grid">
                  <button className="btn btn-primary btn-lg" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr className="my-5" />
        </div>
      </section>
      <section className="pt-5 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-6 text-center mx-auto">
              <h1 className="headings">We're here to help!</h1>
            </div>
          </div>

          <div className="row g-4 g-md-5 mt-0 mt-lg-3">
            <div className="col-lg-6 mt-lg-0">
              <div className="card card-body shadow py-5 text-center h-100">
                <h5 className="mb-3">Main Office Address</h5>
                <ul className="list-inline mb-0">
                  <li className="list-item mb-3 h5 fw-light">
                    <i className="fas fa-fw fa-map-marker-alt me-2 mt-1"></i>
                    Main Bajar Peth Aajra, kolhapur Maharashtra, 416505
                  </li>
                  <li className="list-item mb-3 h5 fw-light">
                    <i className="fas fa-fw fa-phone-alt me-2"></i>
                    (+91)-8237034774
                  </li>
                  <li className="list-item mb-0 h5 fw-light">
                    <i className="far fa-fw fa-envelope me-2"></i>
                    krushnacashewindustry@email.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <iframe
                className="w-100 h-400px grayscale rounded"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d958.2582146192404!2d74.2116543067459!3d16.115601030254137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc07d6ac915c037%3A0xf255f584deef7aef!2sRavalnath%20pure%20water%20supplier!5e0!3m2!1sen!2sin!4v1716227244341!5m2!1sen!2sin"
                style={{ border: 0, height: "500px" }}
                allowfullscreen=" "
                loading="lazy"
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
