import React from "react";

const milletData = [
  {
    title: "Finger Millet (Ragi/Nachani)",
    description:
      "Finger millet, also known as Ragi, is renowned for its high calcium content and is an excellent source of iron and amino acids. It's especially beneficial for growing children and the elderly.",
    uses: "Perfect for making Ragi flour, which can be used in dosas, pancakes, porridges, and even baked goods like cookies and bread.",
  },
];

const MilletSection = ({ title, description, uses }) => (
  <section className="mb-5">
    <h2 className="mb-3 d-flex align-items-center">
      <i
        className="fa-solid fa-seedling"
        style={{ color: "#a2c520", marginRight: "10px", fontSize: "1.5rem" }}
      ></i>
      {title}
    </h2>
    <p style={{ fontSize: "1.25rem", color: "#555", lineHeight: "1.75" }}>
      {description}
    </p>
    <p style={{ fontSize: "1.25rem", color: "#555", lineHeight: "1.75" }}>
      <strong>Uses:</strong> {uses}
    </p>
  </section>
);

const PremiumMilletProducts = () => {
  return (
    <div className="container py-5">
      <section className="mb-5">
        <h1 className="text-center mb-4 headings">Premium Millet Products</h1>
        <p className="lead text-center">
          At Ajara Global Traders, we offer a diverse range of high-quality
          millet products that are nutritious and versatile. Our millets are
          sourced from the best farms, ensuring they are rich in nutrients and
          perfect for a healthy lifestyle.
        </p>
      </section>
      <div className="container mt-5">
        {milletData.map(({ title, description, uses }, index) => (
          <MilletSection
            key={index}
            title={title}
            description={description}
            uses={uses}
          />
        ))}
      </div>
    </div>
  );
};

export default PremiumMilletProducts;
