import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <ul className="nav justify-content-center pb-3 mb-3">
          <li className="nav-item">
            <NavLink to="/" className="nav-link px-2 text-body-secondary">
              Home
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/products"
              className="nav-link px-2 text-body-secondary"
            >
              Product
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/gallery"
              className="nav-link px-2 text-body-secondary"
            >
              Gallery
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/about" className="nav-link px-2 text-body-secondary">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="nav-link px-2 text-body-secondary"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <NavLink
              to="/"
              className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
            >
              {/* <svg className="bi" width="30" height="24">
                <use xlink:to="#bootstrap"></use>
              </svg> */}
            </NavLink>
            <span className="mb-3 mb-md-0 text-body-secondary">
              © 2024 Company, AjaraGlobalTraders.com
            </span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a
                className="fs-5 me-1 text-linkedin"
                href="https://www.linkedin.com/company/ajara-global-traders/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin fa-2x" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-3">
              <a
                className="fs-5 me-1 text-instagram"
                href="https://www.instagram.com/ajara_global_traders/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fa-2x" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-3">
              <a
                className="fs-5 me-1 text-facebook"
                href="https://www.facebook.com/profile.php?id=61560915515980"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook fa-2x" aria-hidden="true"></i>
              </a>
            </li>
            <li className="ms-3">
              <a
                className="fs-5 me-1 text-gmail"
                href="mailto:ajaraglobaltraders@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="far fa-fw fa-envelope fa-2x"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
