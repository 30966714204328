import React from "react";

const jaggeryData = [
  {
    id: "small-cubes",
    title: "Small Jaggery Cubes",
    description:
      "Our small jaggery cubes are perfect for those who prefer a convenient and portion-controlled sweetener. These bite-sized cubes are ideal for direct consumption, tea, coffee, and other beverages, offering a natural sweetness without any additives.",
    uses: [
      "Sweetening hot beverages like tea and coffee",
      "Adding to desserts and confectioneries",
      "A natural sweet treat for snacking",
    ],
  },
  {
    id: "1kg-bricks",
    title: "1 kg Jaggery Bricks",
    description:
      "The 1 kg jaggery bricks are perfect for household use. These bricks are easy to store and can be broken into smaller pieces as needed. They offer a rich, caramel-like flavor that enhances the taste of a variety of dishes.",
    uses: [
      "Cooking and baking",
      "Preparing traditional sweets and savories",
      "Adding to sauces and marinades for a rich flavor",
    ],
  },
  {
    id: "10kg-bricks",
    title: "10 kg Jaggery Bricks",
    description:
      "Our 10 kg jaggery bricks are designed for bulk usage, making them ideal for restaurants, sweet shops, and large families. These bricks ensure you always have a generous supply of natural sweetener at hand.",
    uses: [
      "Bulk cooking and baking",
      "Commercial food preparation",
      "Large-scale traditional sweet making",
    ],
  },
  {
    id: "powder",
    title: "Jaggery Powder",
    description:
      "Jaggery powder offers the same nutritional benefits as our other jaggery products but in a more convenient and easy-to-use form. It dissolves quickly, making it perfect for a variety of culinary applications.",
    uses: [
      "Sweetening beverages and cereals",
      "Sprinkling on fruits and desserts",
      "Mixing into batters and doughs for baking",
    ],
  },
];

const benefits = [
  "Rich in Nutrients: Our jaggery is packed with essential minerals like iron, calcium, magnesium, and potassium, making it a healthier alternative to refined sugar.",
  "Natural Sweetener: Unlike refined sugar, jaggery is unprocessed and retains its natural nutrients, providing a healthier way to satisfy your sweet tooth.",
  "Digestive Health: Jaggery is known for its ability to aid digestion and cleanse the digestive system, promoting overall gut health.",
  "Energy Booster: It provides a quick and sustained energy release, making it an excellent choice for an instant energy boost.",
  "Immunity Booster: Jaggery's rich nutrient profile helps boost the immune system and improve overall health.",
];

const JaggerySection = ({ id, title, description, uses }) => (
  <section id={id} className="mb-5">
    <h2 className="mb-3 d-flex align-items-center">
      <i
        className="fa-solid fa-leaf"
        style={{ color: "#a2c520", marginRight: "10px", fontSize: "1.5rem" }}
      ></i>
      {title}
    </h2>
    <p className="lead">{description}</p>
    <ul>
      {uses.map((use, index) => (
        <li key={index} className="lead">
          {use}
        </li>
      ))}
    </ul>
  </section>
);

export default function Jaggery() {
  return (
    <div className="container">
      <div className="py-5 text-center ">
        <h1 className="mb-4 headings">Premium Jaggery Products</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead">
            At Ajara Global Traders, we offer a variety of high-quality jaggery
            products sourced from the finest sugarcane farms. Our jaggery is
            naturally processed to retain its rich flavor and nutritional value,
            making it a healthier alternative to refined sugar.
          </p>
        </div>
      </div>

      <div className="container mt-5">
        {jaggeryData.map(({ id, title, description, uses }) => (
          <JaggerySection
            key={id}
            id={id}
            title={title}
            description={description}
            uses={uses}
          />
        ))}
      </div>

      <div className="container mt-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-4">
          <div className="col-12 col-sm-8 col-lg-6 d-flex align-items-center justify-content-center">
            <img
              src="/Images/jp.jpg"
              className="d-block mx-auto img-fluid"
              alt="jaggery"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="headings">
              <i
                className="fa-solid fa-leaf"
                style={{
                  color: "#a2c520",
                  marginRight: "10px",
                  fontSize: "1.5rem",
                }}
              ></i>
              Health Benefits of Jaggery
            </h1>
            <ul>
              {benefits.map((benefit, index) => (
                <li key={index} className="lead">
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
