import React from "react";
import "./Gallery.css";

function Gallery() {
  const images = [
    "/Images/GalleryAll/g1.jpeg",
    "/Images/GalleryAll/g2.jpeg",
    "/Images/GalleryAll/g3.jpeg",
    "/Images/GalleryAll/g4.jpeg",
    "/Images/GalleryAll/g5.jpeg",
    "/Images/GalleryAll/g6.jpeg",
    "/Images/GalleryAll/g7.jpeg",
    "/Images/GalleryAll/g8.png",
    "/Images/GalleryAll/g9.png",
    "/Images/GalleryAll/g10.png",
    "/Images/GalleryAll/g11.png",
    "/Images/GalleryAll/g12.png",
  ];

  return (
    <div className="container gallery-container">
      <h2 className="headings">Ajara Global Traders Gallery</h2>
      <br />
      <div className="row">
        {images.map((imgSrc, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className="card gallery-card">
              <img
                src={imgSrc}
                className="card-img-top"
                alt={`Gallery  ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
