import React from "react";
import Features from "../Component/Features";
import Hero from "../Component/Hero";
import Contactbtn from "../Component/Contactbtn";
import Prod from "../Component/Prod";

export default function Home() {
  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/Images/header3.jpeg)`,
    height: "60vh",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  };

  return (
    <div>
      <div style={backgroundImageStyle}>
        <div class=" text-start bottom text-effect">
          <h1>Ajara Global Traders</h1>
        </div>
      </div>
      <div className="container">
        <Hero />

        <Prod />
        <Features />
        <Contactbtn />
        <div className="whatsapp-button-container">
          <a
            href="https://wa.me/919209469493"
            title="Text Us Here"
            data-bs-toggle="popover"
            data-bs-trigger="hover"
            data-bs-content="Popover text"
          >
            <i className="fab fa-whatsapp fa-4x whatsapp-icon"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
