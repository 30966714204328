import React from "react";

export default function Hero() {
  return (
    <div>
      <div className=" py-5  text-center">
        <img
          className="d-block mx-auto mb-4"
          src="\Images\logo.png"
          alt=""
          width="140"
          height="140"
        />
        <h1 className="headings">Ajara Global Traders</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-4 ">
            Ajara Global Traders specializes in the import and export of premium
            agricultural products, including cashew nuts, rice, sweet potatoes,
            and millet. We source directly from Indian farms to deliver quality
            produce to global markets.
          </p>
        </div>
      </div>
    </div>
  );
}
