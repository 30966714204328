import React, { useRef } from "react";
import "../Component/nav.css";
import { NavLink } from "react-router-dom";

function Navbar() {
  const sidemenuRef = useRef(null);

  function openmenu() {
    if (sidemenuRef.current) {
      sidemenuRef.current.style.right = "0";
    }
  }

  function closemenu() {
    if (sidemenuRef.current) {
      sidemenuRef.current.style.right = "-200px";
    }
  }

  return (
    <div>
      <div>
        <div>
          <nav>
            <NavLink to="/" onClick={closemenu}>
              <img src="/Images/logo.png" className="logo" alt="Logo" />
            </NavLink>

            <ul id="sidemenu" ref={sidemenuRef}>
              <li>
                <NavLink to="/" onClick={closemenu}>
                  Home
                </NavLink>
              </li>

              <li className="dropdown">
                <NavLink to="/products" onClick={closemenu}>
                  Product
                </NavLink>
                <div className="dropdown-content">
                  <NavLink to="/products/rice" onClick={closemenu}>
                    Rice
                  </NavLink>
                  <NavLink to="/products/cashew" onClick={closemenu}>
                    Cashew
                  </NavLink>
                  <NavLink to="/products/jaggery" onClick={closemenu}>
                    Jaggery
                  </NavLink>
                  <NavLink to="/products/turmeric" onClick={closemenu}>
                    Turmeric
                  </NavLink>
                  <NavLink to="/products/sweetPotato" onClick={closemenu}>
                    SweetPotato
                  </NavLink>
                  <NavLink to="/products/millet" onClick={closemenu}>
                    Millet
                  </NavLink>
                </div>
              </li>
              {/* <li>
                <NavLink to="/certifications" onClick={closemenu}>
                  Certificates
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/gallery" onClick={closemenu}>
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={closemenu}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" onClick={closemenu}>
                  Contact
                </NavLink>
              </li>
              <i className="fas fa-times" onClick={closemenu}></i>
            </ul>
            <i className="fas fa-bars" onClick={openmenu}></i>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
