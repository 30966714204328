import React from "react";
import { Link } from "react-router-dom";

export default function Product() {
  return (
    <div>
      <section className="my-lg-14 my-12">
        <div className="container text-center">
          <h1 className="headings">PRODUCTS</h1>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/rice">
                <div className="image-container">
                  <img
                    src="/Images/rice.png"
                    className="card-img-top"
                    alt="Rice"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Rice</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/cashew">
                <div className="image-container">
                  <img
                    src="/Images/cashew.png"
                    className="card-img-top"
                    alt="Cashew"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Cashew</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/turmeric">
                <div className="image-container">
                  <img
                    src="/Images/turmeric.png"
                    className="card-img-top"
                    alt="Turmeric"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Turmeric</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/sweetPotato">
                <div className="image-container">
                  <img
                    src="/Images/sweetPotato.png"
                    className="card-img-top"
                    alt="Sweet Potato"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Sweet Potato</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/millet">
                <div className="image-container">
                  <img
                    src="/Images/millets.png"
                    className="card-img-top"
                    alt="Millet"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Millet</h3>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <Link to="/products/jaggery">
                <div className="image-container">
                  <img
                    src="/Images/jaggery.png"
                    className="card-img-top"
                    alt="Jaggery"
                  />
                  <div className="card-body">
                    <h3 className="card-text">Jaggery</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
