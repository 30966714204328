import React from "react";

export default function Features() {
  return (
    <div>
      <section className="pt-0 pt-xl-5">
        <div className="container">
          <div className="row mb-3 mb-sm-4">
            <div className="col-12 text-center">
              <h1 className="headings ">Why Choose Us</h1>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Premium Quality Products</h5>
                <p className="mb-0">
                  We guarantee superior quality by sourcing directly from
                  trusted local Indian farmers, ensuring freshness and
                  authenticity in every product.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Strict Quality Assurance</h5>
                <p className="mb-0">
                  We maintain strict quality control from sourcing to delivery,
                  ensuring products that consistently exceed international
                  standards.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">In-House Manufacturing</h5>
                <p className="mb-0">
                  Our own cashew nuts manufacturing plant allows us to maintain
                  strict quality control, providing consistent and high-quality
                  products.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">
                  Sustainable and Ethical Practices .
                </h5>
                <p className="mb-0">
                  We prioritize sustainable farming and eco-friendly practices,
                  ensuring our products are ethically sourced and
                  environmentally responsible
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Global Network</h5>
                <p className="mb-0">
                  Our strong global network ensures efficient operations and
                  swift product delivery worldwide, meeting your business needs
                  promptly.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body ">
                <div className="icon-lg bg-opacity-10  mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Customer-Centric Approach</h5>
                <p className="mb-0">
                  We focus on building long-term relationships by understanding
                  your needs and providing tailored solutions that exceed your
                  expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
