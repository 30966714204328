import React from "react";

export default function AboutUs() {
  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <h1 className="headings">About Us</h1>
        <p className="lead">
          Welcome to Ajara Global Traders, your trusted source for premium
          agricultural products. Our commitment to quality and sustainability
          drives everything we do.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-bullseye"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Mission
        </h2>
        <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          Our mission is to provide the highest quality agricultural products
          while promoting sustainable farming practices. We aim to create a
          positive impact on the communities we work with and the environment.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-eye"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Vision
        </h2>
        <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          Our vision is to be a global leader in the agricultural industry,
          known for our commitment to quality, innovation, and sustainability.
          We strive to build lasting relationships with our customers,
          suppliers, and the communities we serve.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-hand-holding-heart"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Values
        </h2>
        <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          At Ajara Global Traders, we uphold the values of integrity,
          excellence, and sustainability. These values guide our actions and
          decisions, ensuring that we consistently deliver the best products and
          services to our customers.
        </p>
      </div>

      <section className="mb-lg-9 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 xol-12">
              <div className="text-center mb-7">
                <h2 className="mt-3 headings">
                  {" "}
                  <i
                    className="fa-solid fa-users"
                    style={{
                      color: "#a2c520",
                      marginRight: "10px",
                      fontSize: "1.5rem",
                    }}
                  ></i>
                  Our Leadership Team
                </h2>
              </div>
            </div>
          </div>
          <div className="row gy-5">
            <div className="col-lg-4 col-md-4 col-12">
              <figure className="mb-4 zoom-img">
                <img
                  src="Images\ajay.png"
                  alt="team"
                  className="rounded-3 img-fluid"
                />
              </figure>
              <div className="mb-4  text-center">
                <h4 className="mb-1 ">Ajay krushnaji Desai</h4>
                <span className="fs-6">Founder </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <figure className="mb-4 zoom-img">
                <img
                  src="\Images\Akash.png"
                  alt="team"
                  className="rounded-3 img-fluid"
                />
              </figure>
              <div className="mb-4  text-center">
                <h4 className="mb-1">Akash Ajay Desai</h4>
                <span className="fs-6">Managing Director</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <figure className="mb-4 zoom-img">
                <img
                  src="\Images\amar.png"
                  alt="team"
                  className="rounded-3 img-fluid"
                />
              </figure>
              <div className="mb-4  text-center">
                <h4 className="mb-1">Amar Ajay Desai</h4>
                <span className="fs-6">Managing Director</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
