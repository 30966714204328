import React from "react";

const sweetPotatoData = [
  {
    title: " Sweet Potatoes",
    description:
      "Our sweet potatoes from Chandgad, Maharashtra, are renowned for their superior quality. We deliver to all major cities across India and are now excited to offer our premium sweet potatoes for export.",
  },
];

const benefits = [
  "High in fiber content to support digestive regularity and maintain gut health.",
  "Low in fat and sodium, rich in potassium to regulate blood pressure and promote cardiovascular well-being.",
  "With a low glycemic index, sweet potatoes help manage blood sugar levels, suitable for weight-conscious and diabetic individuals.",
];

const SweetPotatoSection = ({ id, title, description }) => (
  <section id={id} className="mb-5">
    <h2 className="mb-3 d-flex align-items-center">
      <i
        className="fa-solid fa-leaf"
        style={{ color: "#a2c520", marginRight: "10px", fontSize: "1.5rem" }}
      ></i>
      {title}
    </h2>
    <p style={{ fontSize: "1.25rem", color: "#555", lineHeight: "1.75" }}>
      {description}
    </p>
  </section>
);

export default function SweetPotato() {
  return (
    <div className="container py-5">
      <div className="py-5 text-center">
        <h1 className="headings">
          Premium Sweet Potatoes from Ajara Global Traders
        </h1>
        <p className="lead mb-4">
          Discover the finest selection of sweet potatoes sourced from the
          fertile lands of the Chandgad district, India, exclusively brought to
          you by Ajara Global Traders. Renowned for their superior quality and
          rich nutritional profile, our sweet potatoes are a testament to
          excellence in agricultural practices.
        </p>
      </div>

      <div className="container mt-5">
        {sweetPotatoData.map((item, index) => (
          <SweetPotatoSection
            key={index}
            id={index}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>

      <div className="container mt-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-4">
          <div className="col-12 col-sm-8 col-lg-6 d-flex align-items-center justify-content-center">
            <img
              src="/Images/sp.jpg"
              className="d-block mx-auto img-fluid rounded-2"
              alt="sweet potatoes"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6">
            <h2 className="mb-3 d-flex align-items-center">
              <i
                className="fa-solid fa-heart"
                style={{
                  color: "#a2c520",
                  marginRight: "10px",
                  fontSize: "1.5rem",
                }}
              ></i>
              Health Benefits of Sweet Potatoes
            </h2>
            <ul>
              {benefits.map((benefit, index) => (
                <li
                  key={index}
                  className="lead"
                  style={{ fontSize: "1.25rem", lineHeight: "1.75" }}
                >
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
