import React from "react";
import { Link } from "react-router-dom";
export default function Contactbtn() {
  return (
    <div>
      <div className="container col-xxl-8 px-4 py-4">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-4">
          <div className="col-12 col-sm-8 col-lg-6 align-items-center justify-content-center">
            <img
              src="\Images\logo.png"
              className="d-block mx-lg-auto img-fluid  "
              alt="Bootstrap Themes"
              style={{ width: "300px", height: "300px" }}
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 align-items-center justify-content-center">
            <h1 className=" headings">Contact Us</h1>
            <p className="lead justify-content-center ">
              We value your feedback and inquiries. Whether you have questions
              about our products, need assistance with an order, or want to
              learn more about our services, we're here to help. Get in touch
              with us through any of the methods below
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-center">
              <Link to="/contact">
                <button type="button" className="btn-custom ">
                  Contact
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
