import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Product from "./Pages/Products";
import Home from "./Pages/Home";
import Gallery from "./Pages/Gallery";
import Navbar from "./Component/Navbar";
import Rice from "./Pages/Rice";
import Cashew from "./Pages/Cashew";
import Turmeric from "./Pages/Turmeric";
import SweetPotato from "./Pages/SweetPotato";
import Millet from "./Pages/Millet";
import Jaggery from "./Pages/Jaggery";
import Certifications from "./Pages/Certifications";
import Footer from "./Component/Footer";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/products" element={<Product />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/products/rice" element={<Rice />} />
        <Route path="/products/cashew" element={<Cashew />} />
        <Route path="/products/turmeric" element={<Turmeric />} />
        <Route path="/products/sweetPotato" element={<SweetPotato />} />
        <Route path="/products/millet" element={<Millet />} />
        <Route path="/products/jaggery" element={<Jaggery />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
