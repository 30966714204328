import React from "react";

const Cashew = () => {
  const cashewData = [
    {
      title: "White Wholes 180",
      description:
        "Elevate your dishes with these premium, generously sized cashews. With approximately 160-180 nuts per pound, sourced from Ghana, Ivory Coast, and Tanzania, they’re the epitome of quality and taste.",
    },
    {
      title: "White Wholes 240",
      description:
        "Make a statement with these impressively large cashews, perfect for enhancing visual appeal. Each pound contains around 220-240 nuts sourced from premier regions, ensuring unmatched quality.",
    },
    {
      title: "White Wholes 320",
      description:
        "Versatile and beloved, these mid-sized cashews are a kitchen essential. With approximately 300-320 nuts per pound sourced from various origins, they guarantee consistent excellence in every bite.",
    },
    {
      title: "White Wholes 450",
      description:
        "Despite their smaller size, these cashews pack a visual punch. With approximately 440-450 nuts per pound, primarily sourced from Guinea Bissau and Senegal, they’re perfect for adding flair to your creations.",
    },
    {
      title: "Scorched Wholes",
      description:
        "The scorched variety of whole white cashews is favored for applications where aesthetic considerations take a backseat, emphasizing taste and texture instead.",
    },
    {
      title: "White Splits",
      description:
        "These are whole cashews split into halves, providing a delightful crunch and creamy texture. With approximately 350-360 pieces per 250 grams, they are commonly used as a key ingredient in dishes and for adding a flavorful topping or coating.",
    },
    {
      title: "Large White Pieces",
      description:
        "Cashews are diced into four large pieces, offering a satisfying bite and rich flavor. Originating variations determine the size of the pieces, making them versatile for topping, coating, and garnishing various culinary creations.",
    },
    {
      title: "Scorched Splits",
      description:
        "This variety features scorched white splits, offering a unique depth of flavor and slightly toasted aroma. While they may not emphasize visual appeal, they serve as a crucial ingredient in recipes where taste is paramount.",
    },
    {
      title: "Scorched Pieces",
      description:
        "Scorched large white pieces provide a bold, smoky flavor profile, making them suitable for further processing or applications where appearance takes a back seat to taste. They retain the nutritional benefits and creamy texture of cashews.",
    },
    {
      title: "Small White Pieces",
      description:
        "These are whole cashews diced into eight small pieces, perfect for enhancing texture and flavor in various dishes. Whether used as toppings, coatings, or as thickeners in sauces and curries, they add a delightful crunch and nutty taste.",
    },
    {
      title: "Small Scorched Pieces",
      description:
        "Scorched small white pieces offer versatility in culinary applications, providing a rich, toasted flavor and satisfying crunch. While they may not prioritize visual appeal, they deliver on taste and texture in various recipes.",
    },
  ];

  const CashewSection = ({ id, title, description }) => (
    <section id={id} className="mb-5">
      <h2 className="mb-3 d-flex align-items-center">
        <i
          className="fa-solid fa-seedling"
          style={{ color: "#a2c520", marginRight: "10px", fontSize: "1.5rem" }}
        ></i>
        {title}
      </h2>
      <p style={{ fontSize: "1.25rem", color: "#555", lineHeight: "1.75" }}>
        {description}
      </p>
    </section>
  );

  return (
    <div className="container py-5">
      <div>
        <div className="py-5 text-center">
          <h1 className="headings">
            Premium Cashew Varieties from Ajara Global Traders
          </h1>
          <p className="lead mb-4">
            In the realm of gastronomy, where taste buds and eyes converge in
            appreciation, the premium cashew varieties stand out for their
            unparalleled visual appeal and taste sophistication.
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 className="mb-3 d-flex align-items-center">
              <i
                className="fa-solid fa-leaf"
                style={{
                  color: "#a2c520",
                  marginRight: "10px",
                  fontSize: "1.5rem",
                }}
              ></i>
              Krushna Cashew Industry
            </h2>
            <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
              Krushna Cashew, where quality meets care. Pioneering excellence in
              cashew manufacturing, Zero adulteration, 100% pure, natural, raw.
              Delivering premium quality cashew nuts, From the heart of India to
              tables around the world.
            </p>{" "}
            <div className="text-center mt-5">
              <a
                href="https://krushnacashewindustry.com/"
                className="btn btn-primary  view-more-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Site
              </a>
            </div>
            <br />
          </div>
          <div className="col-lg-6">
            <img
              src="/Images/krushna.png"
              className="d-block mx-auto img-fluid rounded-2"
              alt="turmeric"
              loading="lazy"
            />
          </div>
        </div>
        <div className="container mt-5">
          {cashewData.map(({ id, title, description }) => (
            <CashewSection
              key={id}
              id={id}
              title={title}
              description={description}
            />
          ))}
        </div>
      </div>
      <div className="text-center mt-5">
        <a
          href="https://krushnacashewindustry.com/"
          className="btn btn-primary  view-more-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Site
        </a>
      </div>
    </div>
  );
};

export default Cashew;
