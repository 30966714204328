import React from "react";

const riceData = [
  {
    id: "non-basmati",
    title: "Non-Basmati Rice",
    description:
      "Our non-basmati rice is perfect for daily consumption and a variety of culinary applications. Known for its excellent taste and texture, this rice is a versatile choice that can elevate any meal.",
  },
  {
    id: "indrayani",
    title: "Indrayani Rice",
    description:
      "Indrayani rice, a cherished aromatic variety from Maharashtra, stands out for its distinct aroma and rich flavor. Ideal for traditional dishes like biryanis and pulaos, this rice adds a special touch to your culinary creations.",
  },
  {
    id: "ajara-ghansal",
    title: "Ajara Ghansal Rice",
    description:
      "Grown in the Ajara region, Ajara Ghansal rice is a premium variety celebrated for its unique aroma, soft texture, and superior taste. This rice is perfect for preparing authentic regional delicacies and enriching everyday meals.",
  },
  {
    id: "other-varieties",
    title: "Other Varieties of Rice",
    description:
      "In addition to our featured varieties, we offer a diverse selection of other rice types to meet various culinary preferences and requirements. Each variety is meticulously selected to ensure the highest quality and nutritional benefits, providing you with a wide range of options for your kitchen.",
  },
  {
    id: "broken-rice",
    title: "Broken Rice",
    description:
      "Broken rice, composed of fragmented grains, is ideal for applications such as making rice flour, porridge, and dishes where whole grains are not necessary. It is a cost-effective and versatile ingredient that can be used in numerous recipes.",
  },
];

const RiceSection = ({ id, title, description }) => (
  <section id={id} className="mb-5">
    <h2 className="mb-3 d-flex align-items-center">
      <i
        className="fa-solid fa-leaf"
        style={{ color: "#a2c520", marginRight: "10px", fontSize: "1.5rem" }}
      ></i>
      {title}
    </h2>
    <p style={{ fontSize: "1.2rem", color: "#555" }}>{description}</p>
  </section>
);

export default function Rice() {
  return (
    <div className="container mt-5">
      <div className="py-5 text-center">
        <h1 className="headings">
          {" "}
          Premium Rice Varieties from Our Export Portfolio
        </h1>
        <p className="lead mb-4">
          Welcome to Aajra Global Traders, a leading exporter of high-quality
          rice grains. We take pride in offering a diverse range of rice
          varieties, each carefully selected for its exceptional taste, texture,
          and nutritional value. Whether you are looking for premium aromatic
          rice or versatile everyday options, our selection caters to all your
          culinary needs.
        </p>
      </div>
      <div className="container mt-5">
        {riceData.map(({ id, title, description }) => (
          <RiceSection
            key={id}
            id={id}
            title={title}
            description={description}
          />
        ))}
      </div>
    </div>
  );
}
