import React from "react";

const turmericBenefits = [
  "Anti-Inflammatory Properties: Helps reduce inflammation and pain in the body, supporting joint health.",
  "Antioxidant Powerhouse: Protects your cells from damage and promotes overall wellness.",
  "Digestive Aid: Stimulates bile production, aiding in digestion and nutrient absorption.",
  "Immune Booster: Strengthens the immune system, helping to fend off illnesses.",
  "Heart Health: Helps regulate blood pressure and cholesterol levels, contributing to cardiovascular health.",
];

const versatileUses = [
  "Savory Dishes: Perfect for curries, soups, stews, and marinades.",
  "Beverages: Add it to teas, lattes, and smoothies for an extra health boost.",
  "Desserts: Incorporate it into baking for a unique flavor twist.",
  "Natural Colorant: Use it to add a vibrant yellow-orange hue to foods and beverages.",
];

export default function Turmeric() {
  return (
    <div className="container mt-5">
      <div className="py-5 text-center">
        <h1 className="headings">Our Turmeric Powder</h1>
        <p className="lead mb-4">
          At Ajara Global Traders, we offer the finest quality turmeric powder,
          known for its rich aroma and flavor. Our turmeric is carefully
          processed to retain its natural goodness, making it a healthier and
          more flavorful alternative to refined sugar.
        </p>
      </div>

      <div className="row align-items-center">
        <div className="col-lg-6">
          <h2 className="mb-3 d-flex align-items-center">
            <i
              className="fa-solid fa-leaf"
              style={{
                color: "#a2c520",
                marginRight: "10px",
                fontSize: "1.5rem",
              }}
            ></i>
            Rich Aroma and Flavor
          </h2>
          <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
            Our turmeric powder is renowned for its deep, earthy aroma and
            robust flavor. Each pinch adds a vibrant color and a unique taste to
            your dishes, making it an essential ingredient in kitchens
            worldwide. Whether you are preparing a traditional curry, a hearty
            soup, or a marinade, our turmeric enhances the taste and visual
            appeal of your culinary creations.
          </p>
        </div>
        <div className="col-lg-6">
          <img
            src="/Images/tp.jpg"
            className="d-block mx-auto img-fluid rounded-2"
            alt="turmeric"
            loading="lazy"
          />
        </div>
      </div>

      <div className="mt-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-heart"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Health Benefits
        </h2>
        <ul>
          {turmericBenefits.map((benefit, index) => (
            <li key={index} style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
              {benefit}
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-leaf"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Pure and Natural
        </h2>
        <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          We guarantee that our turmeric powder is free from any additives,
          preservatives, or artificial colors. Our turmeric is naturally
          processed, ensuring it retains all its nutritional benefits and pure,
          rich flavor. We believe in providing only the best, and our commitment
          to quality is reflected in every batch of turmeric powder we produce.
        </p>
      </div>

      <div className="mt-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-leaf"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Versatile Ingredient
        </h2>
        <ul>
          {versatileUses.map((use, index) => (
            <li key={index} style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
              {use}
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-leaf"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Sustainable Sourcing
        </h2>
        <p style={{ fontSize: "1.25rem", lineHeight: "1.75" }}>
          We are dedicated to sustainable farming practices. Our turmeric is
          sourced from trusted farmers who adhere to environmentally friendly
          and ethical farming methods. By supporting sustainable agriculture, we
          ensure that our turmeric is grown without harmful pesticides and
          fertilizers, promoting a healthy ecosystem and a sustainable future.
        </p>
      </div>
    </div>
  );
}
