import React from "react";

const Certifications = () => {
  // const [showModal, setShowModal] = useState(false);
  // const [modalImage, setModalImage] = useState("");

  // const certifications = [
  //   {
  //     imgSrc: "Images/tp.jpg",
  //     title: "Turmeric Powder",
  //   },
  //   {
  //     imgSrc: "Images/sp.jpg",
  //     title: "Sweet Potatoes",
  //   },
  //   // Add more certifications as needed
  // ];

  // const openModal = (imgSrc) => {
  //   setModalImage(imgSrc);
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  //   setModalImage("");
  // };

  return (
    // <div className="container mt-5">
    //   <div className="row">
    //     {certifications.map((cert, index) => (
    //       <div className="col-md-4 mb-4" key={index}>
    //         <img
    //           src={cert.imgSrc}
    //           alt={cert.title}
    //           className="img-fluid"
    //           onClick={() => openModal(cert.imgSrc)}
    //           style={{ cursor: "pointer" }}
    //         />
    //       </div>
    //     ))}
    //   </div>

    //   {showModal && (
    //     <div
    //       className="modal show"
    //       tabIndex="-1"
    //       role="dialog"
    //       style={{
    //         display: "block",
    //         backgroundColor: "rgba(0,0,0,0.8)",
    //         position: "fixed",
    //         top: 0,
    //         left: 0,
    //         width: "100%",
    //         height: "100%",
    //         zIndex: 1050,
    //       }}
    //     >
    //       <div
    //         className="modal-dialog modal-dialog-centered"
    //         role="document"
    //         style={{ maxWidth: "80%", margin: "auto" }}
    //       >
    //         <div className="modal-content">
    //           <div className="modal-body p-0">
    //             <img
    //               src={modalImage}
    //               alt="Certification"
    //               className="img-fluid"
    //             />
    //           </div>
    //           <div className="modal-footer justify-content-center">
    //             <button
    //               type="button"
    //               className="btn btn-secondary"
    //               onClick={closeModal}
    //             >
    //               Close
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div>
      <h1>comming soon</h1>
    </div>
  );
};

export default Certifications;
