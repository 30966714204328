import React from "react";
import { Link } from "react-router-dom";
export default function Prod() {
  return (
    <div>
      <section className="my-lg-14 my-12">
        <div>
          <div className="container text-center ">
            <h1 className="headings">Our Best Products</h1>
            <div className="row">
              <div className="col">
                <div className="image-container">
                  <img
                    src="/Images/cashew.png"
                    className="card-img-top"
                    alt="..."
                  />

                  <div className="card-body">
                    <h3 className="card-text fw-bold">Cashew</h3>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image-container">
                  <img
                    src="/Images/rice.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h3 className="card-text fw-bold">Rice</h3>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image-container">
                  <img
                    src="/Images/jaggery.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h3 className="card-text fw-bold">Jaggery</h3>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="image-container">
                  <img
                    src="/Images/sweetPotato.png"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h3 className="card-text fw-bold">Sweet Potato</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Link to="/products">
                  <button className="view-more-btn">View More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
